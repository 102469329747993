import React from 'react';
import moment from 'moment';

export default function AssetDayWiseReport({ fromDate, toDate, dayWiseMetersData = [], projectName }) {

    const parseRunningTime = (timeString) => {
        if (!timeString) return 0;
        const match = timeString.match(/(\d+)\s*hours?,?\s*(\d+)?\s*mins?/);
        if (!match) return 0;
        const hours = parseInt(match[1], 10);
        const minutes = match[2] ? parseInt(match[2], 10) : 0;
        return hours + minutes / 60;
    };

    let totalRunningHours = 0;
    let totalConsumption = 0;
    let totalCost = 0;

    dayWiseMetersData.forEach((dayData) => {
        dayData.meters.forEach((meter) => {
            const runningHours = parseRunningTime(meter.value);
            const consumption = meter.rating * runningHours;
            const cost = consumption * meter.unitPrice;

            totalRunningHours += runningHours;
            totalConsumption += consumption;
            totalCost += cost;
        });
    });

    return (
        <div className="reports-tables-container" style={{ marginTop: '50px' }}>
            <table className="table-header day-wise-report-table table-bordered monthly-report-table">
                <tbody>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>{projectName}</th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>
                            From: {moment(fromDate).format('YYYY-MM-DD')} 00:00 AM - To: {moment(toDate).format('YYYY-MM-DD')} 11:59 PM
                        </th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            Operational - Day-wise Report - Powered by{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.greenvironmentindia.com/">
                                Greenvironment India
                            </a>
                        </th>
                    </tr>
                </tbody>
            </table>
            <table className="day-wise-report-table row-border order-column" style={{ width: '100%' }}>
                <thead style={{ overflowX: 'auto', width: '100%' }}>
                    <tr className="table-header-light-bg">
                        <th className="tg-e9yv">Date</th>
                        <th className="tg-e9yv">Asset Name</th>
                        <th className="tg-e9yv">Meter Name</th>
                        <th className="tg-e9yv">Running Hours</th>
                        <th className="tg-e9yv">Rating (kW)</th>
                        <th className="tg-e9yv">Unit Price (₹)</th>
                        <th className="tg-e9yv">Consumption (kWh)</th>
                        <th className="tg-e9yv">Cost (₹)</th>
                    </tr>
                </thead>
                <tbody>
                    {dayWiseMetersData.map((dayData, index) => (
                        <React.Fragment key={index}>
                            {dayData.meters.map((meter, idx) => {
                                const runningHours = parseRunningTime(meter.value);
                                const consumption = meter.rating * runningHours;
                                const cost = consumption * meter.unitPrice;

                                return (
                                    <tr key={idx}>
                                        {idx === 0 ? (
                                            <td rowSpan={dayData.meters.length} style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>
                                                {dayData.date}
                                            </td>
                                        ) : null}
                                        <td>{meter.assetName}</td>
                                        <td>{meter.metername}</td>
                                        <td>{runningHours.toFixed(2)} Hrs</td>
                                        <td>{meter.rating}</td>
                                        <td>₹ {meter.unitPrice}</td>
                                        <td>{consumption.toFixed(2)} kWh</td>
                                        <td>₹ {cost.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </React.Fragment>
                    ))}
                    <tr className="table-header-light-bg" style={{ fontWeight: 'bold' }}>
                        <td colSpan="3" style={{ textAlign: 'right' }}>Total</td>
                        <td>{totalRunningHours.toFixed(2)} Hrs</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{totalConsumption.toFixed(2)} kWh</td>
                        <td>₹ {totalCost.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
