import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientById, getUserProjects } from '../Redux/actions';
import { useNavigate, useParams } from 'react-router-dom';
import SupersetDashboard from './AiDashboard/SupersetDashboard';
import SensorHealth from './Dashboard/SensorHealth';

export default function Assets({type, clientId}) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const state = useSelector(st => st);
    const { userProjectsList, getClientById: cData } = state;
    localStorage.setItem('clientLogo', cData?.data?.data?.media?.path);
    let { client_id } = useParams();
    if (clientId) {
        client_id = clientId
    }
    const [userProjects, setUserProjects] = useState([])
    const [client_int_id, setClientIntId] = useState('')
    const [clientFound, setClientFound] = useState(true);
    const [pidList, setPidList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setPidList([]);
        dispatch(getUserProjects(client_id)).then((res) => {
            setShowLoader(false);
            if (res && res.status === 200) {
                setUserProjects(res.data.data)
                const pids = res.data.data?.map(item => item?.pid);
                setPidList(pids)
            }
        });
        dispatch(getClientById(client_id)).then((resp) => {
            const response = resp?.data?.data
            if (!response) {
                setClientFound(false)
                return
            }
            setClientIntId(response.client_int_id)
        });
    }, [client_id])


    const loadKpisData = (item) => {
        const val = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
        const card_growth = val > 50 ? 'positive' : 'negative';
        return (
            <div className="kpi-card" data-card-id="{{card_id}}">
                <div style={{ padding: 10, textAlign: 'left' }} className="cursor-hand">
                    <p className="card-title">{item.projname?.replace("WA-", "").trim()},  #{item.pid}</p>
                    <div>
                        Plant Type: <b>{item.planttype}</b>, 
                        Capacity: <b>{item.plantcapacity}</b>, 
                        Segment: <b>{item?.facilities?.segment}</b>
                    </div>
                    {/* <div style={{ color: card_growth === 'negative' ? 'red' : 'green', fontWeight: 'bold', display: 'flex' }}>
                        <div style={{ marginTop: 8 }}>{val}%</div>
                        <span >
                            {card_growth === 'negative' ?
                                <ArrowDownwardIcon className='percentage-arrow' />
                                :
                                <ArrowUpwardIcon className='percentage-arrow' />
                            }
                        </span>
                    </div> */}
                </div>
            </div>)
    }

    if (userProjectsList?.isFetching || showLoader) {
        return <div className='w3-center' style={{ marginTop: 30 }}>
            <CircularProgress />
        </div>
    } else if (!clientFound) {
        return <Grid container justifyContent={"center"} style={{ marginTop: '160px' }}>
            <h5 className='w3-text-grey'>  No Data Found</h5>
        </Grid>
    } else if (client_int_id && userProjects?.length === 0) {
        return <Grid container justifyContent={"center"} style={{ marginTop: '160px' }}>
            <h5 className='w3-text-grey'>No Projects are deployed for this client</h5>
        </Grid>
    }

    return (
        <Grid>
            <div className='w3-padding-large'>
                <div>
                    {(type === "sample-dashboard" || type === 'Home') && (
                        <h5 className="card-title">Home Dashboard</h5>
                    )}
                    {client_int_id && pidList?.length > 0 && (
                        <SupersetDashboard 
                            client_id={client_id} 
                            dashboardType='Home' 
                            dashboardHeight="620px"
                            projectList={pidList}
                        />
                    )}
                </div>
                {type !== "sample-dashboard" && (
                    <div className="grid-container" style={{marginTop: 30}}>
                        {userProjects?.map((item, index) => (
                            <div className="grid-item" key={index} onClick={() => navigate(`/client/${item.client_id}/projects/live/${item.pid}`, { replace: true })}>
                                {loadKpisData(item)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Grid>
    )
}