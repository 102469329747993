import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import SustainabilityDashboard from './SustainabilityDashboard';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReports } from '../../../Redux/actions';

export default function WaterBalance() {
    const dispatch = useDispatch();
    const { client_id, facilityId } = useParams();
    const [userWbReports, setUserWbReports] = useState([])
    const [isUserWbReportsSet, setIsUserWbReportsSet] = useState(false)
    const state = useSelector(st => st);
    const { currentUser: { uniqueId }, role, allReports } = state
    const currentUserRole = role?.data?.data;

    useEffect(() => {
        setUserWbReports([]);
        const params = {
            type: 'File',
            facility_id: facilityId
        }
        dispatch(getAllReports(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                const reportData = res?.data?.allData?.filter(item => item?.section_name === 'Water Balance');
                const userReports = []
                if (reportData?.length > 0) {
                    const userRoles = ['admin', 'spoc'];
                    const isAdmin = currentUserRole?.filter((item) => (userRoles?.includes(item?.role)) && item.client_id === null)
                    for (const row of reportData) {
                        if (isAdmin?.length > 0) {
                            userReports.push({ label: row.report_name, value: row.media_id, category: row.category })
                        } else {
                            const user_id_data = row.user_id;
                            if (user_id_data) {
                                if (user_id_data.some(user => user.email === uniqueId)) {
                                    userReports.push({ label: row.report_name, value: row.media_id, category: row.category })
                                }
                            }
                        }
                    }
                    if (userReports?.length > 0) {
                        setUserWbReports(userReports);
                    }
                } else {
                    setUserWbReports(userReports);
                }
                setIsUserWbReportsSet(true)
            }
        });
    }, [facilityId])

    if (allReports?.isFetching) {
        return <div className='w3-center' style={{ marginTop: 30 }}>
            <CircularProgress />
        </div>
    }

    let content;

    if (userWbReports?.length > 0) {
    content = (
        <SustainabilityDashboard
        client_id={client_id}
        waterBalanceReportList={userWbReports}
        />
    );
    } else if ( isUserWbReportsSet ) {
    if (allReports?.data?.data?.length === 0) {
        content = <div>Water balance report is not yet added</div>;
    } else {
        content = <div>You don't have permission to view the water balance report</div>;
    }
    }

    return (
        <div className="w3-padding-large" >
            <h5 className="card-title hide-in-print">Water Balance</h5>
            {content}
        </div>
      );
}