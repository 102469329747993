
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { Can } from '../../Context/Can';
import LiveView from '../Live';
import ManageProjects from './ManageProjects';
import AssetsDetails from '../AssetsDetails';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SiteVisitReportList from './SiteVisitReportList';
import ReportsView from '../AiDashboard/ReportsView';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserRole, getProjectById } from '../../Redux/actions';

export default function ProjectCommon() {
    const { client_id, tabId, id } = useParams();
    const pid = Number(id);
    const navigate = useNavigate();
    const [value, setValue] = useState('1');
    const [userRole, setUserRole] = useState([])
    const [userAccess, setUserAccess] = useState([]);
    const [clPermissions, setClPermissions] = useState(null);
    const [facility, setFacility] = useState(null);
    const [location, setLocation] = useState(null);
    const [showLoader, setShowLoader] = useState( true );
    const dispatch = useDispatch();

    const state = useSelector(st => st)
    const { getClientById: clientData } = state

    const projectQueryParams = localStorage.getItem("projectQueryParams");

    useEffect(()=> {
        if (userRole?.length === 0 ) {
            dispatch(getCurrentUserRole()).then((userResp) => {
                setUserRole(userResp?.data?.data)
            })
        }
    },[1])

    useEffect(()=> {
        dispatch(getProjectById(client_id, id)).then(res=> {
            setFacility(res?.data?.projectData?.facility_id)
            setLocation(res?.data?.projectData?.location_id)
        })
    },[])

    useEffect(() => {
        if ( userAccess.length > 0 ){
            setShowLoader(false)
            return
        }
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission?.permissions?.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                        if (permission?.permissions?.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
            setShowLoader(false)
        }
    }, [userRole])

    let canCreate = false;
    let canEdit = false;
    let canDelete = false;
    let canView = false;
    let clientAdmin = false;
    let clientView = false;
    let clientLevelPermission = null;

    useEffect(() => {
        const clientPermission = clientData?.data?.data?.permission;
        if (clientPermission && !clPermissions) {
            const permissions = Object.keys(clientPermission)
                .filter(key => clientPermission[key])
                .map(key => key.charAt(0).toUpperCase() + key.slice(1));
            setClPermissions(permissions);
        }
    }, [clientData, clPermissions]);

    if (userAccess?.length > 0) {
        userAccess?.find(uItem => {
            uItem?.permission?.find(pItem => {
                if (pItem.type !== 'project' && !id) {
                    canCreate = true;
                }
                if ((pItem.itemId == id || facility === pItem.itemId || location === pItem.itemId) && clPermissions?.includes("Project")) {
                    if (pItem.permissions.edit) {
                        canEdit = true
                        canDelete = true
                    }
                    if (pItem.permissions.view) {
                        canView = true
                    }
                }
                if (pItem.client_id === client_id && pItem.type === 'client' && pItem.permissions.edit && !clientAdmin) {
                    canEdit = true
                    canDelete = true
                    clientAdmin = true;
                    clientLevelPermission = true;
                }else {
                    clientLevelPermission = false
                }
                if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view && !clientView) {
                    canView = true
                    clientView = true
                    clientLevelPermission = true;
                } else {
                    clientLevelPermission = false
                }
            })
            if (uItem.role === 'admin') {
                canEdit = true
                canDelete = true
            }
            if(uItem.role === 'spoc') {
                canView = true
            }
        })  
    }

    useEffect(() => {
        setValue(tabId)
      }, [tabId]);

    const handleTabSwitch = (event, newValue) => {
        setValue(newValue);
        navigate(`/client/${client_id}/projects/${newValue}/${pid}`)
      };    

      const tabNames = ['live', 'summary-table', 'report-view', 'edit', 'view', 'site-visit-report'];

    let found = false;
    for (const tabName of tabNames) {
        if (window.location.pathname.includes(tabName)) {
            found = true;
            break;
        }
    }

    if (!found) {
        navigate('/page-not-found');
    }

    if (showLoader) {
        return <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', width: '100vw', height: '500px',  alignItems: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    }

    const scrollToTop = () => {
        document.getElementById("project-container")?.scrollIntoView({ behavior: "smooth" });
    };

    return <>
        <div id="project-container" className='w3-padding-large'>
            <div >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box id="digital-twin-tab-container" sx={{ borderBottom: 1, borderColor: 'divider',  display: 'flex', alignItems: 'center' }}>
                        <   IconButton 
                            onClick={() => projectQueryParams ? navigate(`/client/${client_id}/projects?${projectQueryParams?.toString()}`) : navigate(`/client/${client_id}/projects`)}
                            color="inherit">
                                <ArrowBackIosIcon fontSize='small'/>
                            </IconButton>
                            <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                                <Tab label="Live View" value="live" />
                                <Tab label="Summary Table" value="summary-table" />
                                <Tab label="Reports" value="report-view" />
                                <Tab label="Site Visit" value="site-visit-report" />
                                <Tab 
                                    label={(!canEdit && canView)  ? "View Project" : "Edit Project"} 
                                    value={(!canEdit && canView)  ? "view" : "edit"} 
                                />
                                
                            </TabList>
                        </Box>
                        <TabPanel style={{ padding: '0px' }} value="live"><Can I="view" a="Live"><LiveView /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="summary-table"><Can I="view" a="Assets"><AssetsDetails /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="report-view"><Can I="view" a="ReportView"><ReportsView scrollToTop={scrollToTop} /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="site-visit-report"><Can I="view" a="SiteVisitReport"><SiteVisitReportList /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value={(!canEdit && canView)  ? "view" : "edit"}  ><Can I="view" a="EditProject"><ManageProjects 
                            userRoleCommn={userRole}
                            userAccessCommn={userAccess}
                            clPermissionsCommn={clPermissions}
                            canCreateCommn = {canCreate}
                            canEditCommn = {canEdit}
                            canDeleteCommn = {canDelete}
                            canView = {canView}
                            clientAdmin = {clientAdmin}
                            clientView = {clientView}
                            clientLevelPermission={clientLevelPermission}
                        /></Can></TabPanel>
                        
                    </TabContext>
                </Box>
            </div>
        </div>
    </>
}