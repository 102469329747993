
import Grid from '@mui/material/Grid';
import { Checkbox, ListItemText, OutlinedInput, TextField, FormControl, InputLabel, CircularProgress, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { createNewReport, deleteReport, getReportById, getUsersByClientId, setStoreData, updateReport, getClientById } from '../../Redux/actions';
import Paper from '@mui/material/Paper';
import { DeleteIcon } from '../Common/TableUI';
import { Can } from '../../Context/Can';
import ConfirmDialog from '../Common/ConfirmDialog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const TYPE_FILE = 'File';

export default function CreateReport() {

    const location = useLocation();
    const locationState = location?.state;

    let navigate = useNavigate();
    let { id, client_id, action } = useParams();
    const initialForm = {
        dashboardName: "",
        section: "Water Balance",
        type: "File",
        supersetFilter: "",
        formula: "",
        name: "",
        facility_id: "",
    }
    const [form, setForm] = useState(initialForm);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [userList, setUserList] = useState([]);
    const [allowedUsers, setAllowedUsers] = useState([])
    const [disableButton, setDisableButton] = useState(false);
    const [uploadedSheet, setUploadedSheet] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedMediaItem, setSelectedMediaItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [facilityList, setFacilityList] = useState([])
    const disableUploadReport = form?.category === 'Water Balance Master'
    const sectionList = ['Water Balance', 'Energy Consumption']
    const [itemNotFound, setItemNotFound] = useState(false);

    useEffect(() => {
        dispatch(getClientById(client_id)).then((res) => {
            if(!res?.data?.data) {
                setItemNotFound(true)
            }
            const clientFacilities = res?.data?.data?.facilities;
            if (clientFacilities) {
                setFacilityList(clientFacilities)
            }
            
        });
    }, []);

    useEffect(() => {
        if (!form.facility_id) {
            return
        }
        dispatch(getUsersByClientId(client_id, form.facility_id)).then((resp) => {
            if (!resp) {
                console.error("Failed fetching getUsersByClientId");
            }
            setUserList(resp.data.data)
        })
    }, [form.facility_id])

    useEffect(() => {
        if (!id) {
            return;
        }
        if(action === 'create' && id) {
            navigate('/page-not-found')
            return
        }
        dispatch(getReportById(client_id, id)).then((resp) => {
            if (!resp.data.success || !resp?.data?.data) {
                setErrorMessage(resp?.data?.message);
                setItemNotFound(true);
                return;
            }
            const response = resp.data.data
            const { superset_dashboard_name, superset_dashboard_id, section_name, superset_filter,
                user_id, formula, type, report_name, media_id, category, facility_id } = response;

            const editData = {
                dashboardName: superset_dashboard_name,
                dashboardId: superset_dashboard_id,
                supersetFilter: superset_filter,
                section: section_name,
                client_id: client_id,
                formula: formula,
                type: type,
                name: report_name,
                category: category,
                facility_id: facility_id
            }

            if (media_id) {
                const reportPath = response?.media?.path?.split('/');
                setSelectedMediaItem(media_id);
                if (reportPath?.length > 0) {
                    const reportFilename = reportPath[reportPath.length - 1];
                    editData.custom_report_path = reportFilename;
                }
            }
            let allowedUsersList = []
            user_id?.map((item) => {
                allowedUsersList.push(item.email)
            });
            setAllowedUsers(allowedUsersList)
            setForm(editData)
        })
    }, [id]);

    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        const err = { ...errors };
        const formData = { ...form };

        if (err[fieldName]) {
            err[fieldName] = null;
        }
        if (fieldName === 'type') {
            // formData.dashboardName = '';
            // formData.dashboardId = '';
            // formData.supersetFilter = '';
            // formData.formula = '';
            formData.section = 'Water Balance';
            formData.category = 'Water Balance Upload';
            setErrors({});
        } else if (fieldName=== 'facility_id') {
            setAllowedUsers([])
            setErrors(err);
        }
        else {
            setErrors(err);
        }
        formData[fieldName] = value;
        setForm(formData);
        if (disableButton) {
            setDisableButton(false)
        }
    };

    const handleSelectUsers = (event) => {
        const { value } = event.target;
        setAllowedUsers(
            typeof value === 'string' ? value.split(',') : value,
        );
        if (disableButton) {
            setDisableButton(false)
        }
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/report`);
    }

    const validateData = () => {
        const err = { ...errors };
        const formData = { ...form };
        let hasErrors = false;
        if (!formData.name.trim()) {
            hasErrors = true;
            err.name = 'This field is mandatory';
        }
        if (!formData.type) {
            hasErrors = true;
            err.type = 'This field is mandatory';
        }

        if (!uploadedSheet && !formData.custom_report_path) {
            hasErrors = true;
            err.uploadedFile = 'Please upload a file';
        }
        if (!formData.section) {
            hasErrors = true;
            err.section = 'This field is mandatory';
        }
        if ( !formData.facility_id ) {
            hasErrors = true;
            err.facility_id = 'Please select a facility';
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        setDisableButton(true)
        e.preventDefault();
        const checkForm = validateData();
        if (checkForm) {
            const formData = new FormData();
            const data = {
                report_name: form.name,
                section_name: form.section,
                client_id: client_id,
            }
            if ( form.type === TYPE_FILE ) {
                data.type = TYPE_FILE;
                data.category = form?.section === 'Water Balance' ? 'Water Balance Upload' : 'Energy Consumption Upload'
                data.facility_id = form.facility_id;
                data.superset_dashboard_name = '';
                data.superset_dashboard_id = '';
                data.superset_filter = '';
                if (uploadedSheet) {
                    formData.append('excelFile', uploadedSheet);
                }
            }

            let selectedUserIds = []
            if ( form?.section === 'Water Balance' ){
                allowedUsers?.map((item) => {
                    const user = userList?.find((user) => user.user_id === item);
                    if (user) {
                        const data = {
                            name: user.name,
                            id: user?.authUser?.id,
                            email: user.user_id
                        }
                        selectedUserIds.push(data)
                    }
                });
                data.user_id = selectedUserIds;
            } else {
                data.user_id = [];
            }
            
            formData.append('data', JSON.stringify(data))
            if(selectedItem) {
                formData.append('deletedItem', JSON.stringify(selectedItem))
            }
            if (id) {
                dispatch(updateReport(formData, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                })
            } else {
                dispatch(createNewReport(formData)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                        // setForm(initialForm);
                    }
                });
            }
        }
    };

    const handleFileChange = (event) => {
        event.preventDefault();
        let uploadedFile;
        if (event.target.files.length) {
            const err = { ...errors };
            if (err['uploadedFile']) {
                err['uploadedFile'] = null;
            }
            uploadedFile = event.target.files[0];
            if (uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                err.uploadedFile = 'Please upload an excel sheet'
            } else {
                setUploadedSheet(uploadedFile)
            }
            setErrors(err);
            event.target.value = ''
        }
        if (disableButton) {
            setDisableButton(false)
        }
        // setShowConfirmUploadDialog(true)

    }

    const triggerDelete = () => {
        setSelectedItem(id);
        setShowConfirmDialog(true);
    }

    const handleDeleteReport = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteReport(client_id, id)).then((resp) => {
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
            })
        }
    }

    if (errorMessage) {
        return (
            <h5 className='w3-text-grey w3-padding-top-32 w3-center'>
                {errorMessage}
            </h5>
        )
    }
    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    } else if (!id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Create report not available due to invalid client id.
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <Paper elevation={1} style={{ margin: '16px 0px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container className='w3-padding'>
                    <Grid item md={12} style={{ display: 'flex' }}>
                        <IconButton
                            style={{ marginBottom: '8px'}}
                            onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/report`)}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <h6 style={{ paddingBottom: '8px' }}>{id ? 'Edit Report' : 'Create New Report'}</h6>
                    </Grid>
                    <Grid container item md={12}>
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <TextField
                                required
                                fullWidth
                                label="Report Name"
                                value={form.name}
                                disabled={disableUploadReport}
                                onChange={(e) => handleChange(e, 'name')}
                            />
                        </Grid>
                        {errors.name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.name}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel required disabled={disableUploadReport} id="demo-multiple-name-label">Type</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={disableUploadReport}
                                    value={form.type}
                                    name="type"
                                    label="Type"
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    onChange={(e) => handleChange(e, 'type')}
                                >
                                    {[TYPE_FILE].map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {errors.type && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.type}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel required disabled={disableUploadReport} id="demo-multiple-name-label">Section</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={disableUploadReport}
                                    value={form.section}
                                    name="section"
                                    label="Section"
                                    onChange={(e) => handleChange(e, 'section')}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                >
                                    {sectionList?.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {errors.section && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.section}</div>}
                        {form.type === TYPE_FILE && (
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel required disabled={disableUploadReport} id="demo-multiple-name-label">Facility</InputLabel>
                                    <Select
                                        fullWidth
                                        disabled={disableUploadReport}
                                        value={form.facility_id}
                                        name="facility_id"
                                        label="facility_id"
                                        onChange={(e) => handleChange(e, 'facility_id')}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                    >
                                        {facilityList?.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>{item.facility_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {errors.facility_id && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.facility_id}</div>}
                        {form.type === TYPE_FILE && (
                            <Grid container item md={12} className='w3-margin-bottom'>
                                <label htmlFor="logo-file">
                                    <input
                                        style={{ display: 'none' }}
                                        required
                                        id="logo-file"
                                        name="logoFile"
                                        disabled={disableUploadReport}
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={handleFileChange}
                                    />

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Button
                                                // className="w3-margin-top"
                                                disabled={disableUploadReport}
                                                color="primary"
                                                variant="outlined"
                                                size="medium"
                                                component="span">
                                                Upload Excel Sheet *
                                            </Button>
                                        </div>
                                    </div>
                                    {errors.uploadedFile && <div className='error-text w3-margin-bottom'>{errors.uploadedFile}</div>}
                                </label>
                                {uploadedSheet && (
                                    <div className='selected-file w3-text-blue w3-margin-left'>
                                        {uploadedSheet?.name}
                                        <IconButton
                                            className='cursor-pointer w3-margin-left'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setUploadedSheet(null);
                                                setForm({ ...form, custom_report_path: null });
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>

                                    </div>
                                )}
                                {(form.custom_report_path && !uploadedSheet) && (
                                    <div style={{ padding: disableUploadReport ? '6px' : '0 6px' }} className='selected-file w3-text-blue w3-margin-left'>
                                        {form.custom_report_path}
                                        {(form.category === 'Water Balance Upload' || form.category === 'Energy Consumption Upload') && (
                                            <IconButton className="cursor-pointer w3-margin-left" onClick={(e) => {
                                                e.stopPropagation();
                                                setUploadedSheet('');
                                                setForm({ ...form, custom_report_path: null });
                                                setSelectedItem(selectedMediaItem)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                )}
                            </Grid>
                        )}
                        {disableUploadReport && (
                            <Grid item className='report-warning-msg'>
                                You won't be able to modify the disabled fields as they are synchronized. If you wish to make changes, please update them within the configuration sheet
                            </Grid>
                        )}
                        { form?.section === 'Water Balance' && (<Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-name-label">Allowed Users</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={allowedUsers}
                                    onChange={handleSelectUsers}
                                    input={<OutlinedInput label="Allowed Users" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                >
                                    {userList?.map((item) => (
                                        <MenuItem key={item.id} value={item.user_id}>
                                            <Checkbox checked={allowedUsers?.indexOf(item?.user_id) > -1} />
                                            <ListItemText primary={item.user_id} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>)}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start' }} className='w3-margin-bottom'>
                                {id && (
                                    <Can do="manage" on="DeleteReport">
                                        <Button
                                            variant="outlined"
                                            startIcon={<DeleteIcon />}
                                            color="error"
                                            onClick={() => triggerDelete()}
                                        >
                                            Delete
                                        </Button>
                                    </Can>
                                )}
                            </Grid>
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end' }} className='w3-margin-bottom'>
                                <Button
                                    variant="outlined"
                                    onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/report`)}
                                >
                                    Cancel</Button>
                                <Button
                                    disabled={disableButton}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                        {showConfirmDialog
                            ? (
                                <ConfirmDialog
                                    handleCancel={() => {
                                        setSelectedItem({})
                                        setShowConfirmDialog(false)
                                    }}
                                    handleOk={handleDeleteReport}
                                    message="Are you sure to delete this ?"
                                    description={disableUploadReport ? `Note: If you intend to delete this report, please ensure its removal from the configuration sheet as well. Failure to do so might result in the re-generation of this report upon resynchronization.` : ''}
                                />
                            ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
