import { Grid, Paper, TextField, Button, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip, InputAdornment, ListItemText, Autocomplete, CircularProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { DeleteIcon } from '../Common/TableUI';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { createMaintenanceLog, deleteMaintenanceMedia, getAssetMaintenanceSchedules, getJoinedGreenUsers, getMaintenanceLogById, setStoreData, updateMaintenanceLog } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import AlertDialog from '../Common/AlertDialog';
import moment from 'moment';
import { isValidDate } from '../../utils/Common';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ConfirmDialog from '../Common/ConfirmDialog';

export default function ManageMaintenanceLog() {

    const { client_id, id, maintenanceId, tabId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location?.state;
    const maintenanceLogQueryParams = localStorage.getItem('maintenanceLogQueryParams')

    let initialForm = {
        maintenanceSchedule: '',
        description: '',
        approvedBy: '',
        cost: '',
        status: '',
        remarks:''
    }

    const [form, setForm] = useState(initialForm);
    const [scheduledDate, setScheduledDate] = useState(null)
    const [plannedDate, setPlannedDate] = useState(null)
    const [completionTime, setCompletionTime] = useState(null)
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [itemNotFound, setItemNotFound] = useState(false);
    const error = {};
    const [errors, setErrors] = useState(error);
    const [disableButton, setDisableButton] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);
    const [uploadedImage, setUploadedImage] = useState();
    const [deletedItem, setDeletedItem] = useState([]);
    const [maintenanceSchedules, setMaintenanceSchedules] = useState(null)
    const [greenUsers, setGreenUsers] = useState([])
    const [selectedMaintnceEngineers, setSelectedMaintnceEngineers] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [showLoader, setShowLoader] = useState( maintenanceId ? true : false )

    useEffect(() => {
        dispatch(getAssetMaintenanceSchedules(id)).then((res) => {
            if (res && res.status === 200) {
                setMaintenanceSchedules(res.data.scheduleData)
            }
        });
        dispatch(getJoinedGreenUsers()).then((res)=> {
            if (!res?.data?.success){
                return
            }
            const greenUsersAvailable = res?.data?.allData
            const activeUsers = greenUsersAvailable?.filter(item => item.status != 'Inactive');
            const users = activeUsers?.map(item => item.name)
            setGreenUsers(users)
            setApprovers(activeUsers)
        })
    },[])

    useEffect(() => {
        if (maintenanceId) {
            dispatch(getMaintenanceLogById(maintenanceId)).then((resp) => {
                const response = resp?.data?.logData
                if (!response) {
                    setItemNotFound(true);
                    setShowLoader(false)
                    return;
                }

                const { approved_by, completion_time, cost, description, maintenance_engineer, planned_date, status, media,
                    maintenance_schedule_id, offset_time_days, remarks, scheduled_date } = response
                
                setSelectedFile([])
                media.forEach((mediaItem, idx) => {
                    const fileData = {
                        id: mediaItem.id,
                        fileUrls: mediaItem.path,
                        type: mediaItem.type
                    }
                    setSelectedFile((prevFiles) => [...prevFiles, fileData]);
                })

                if ( completion_time || planned_date || scheduled_date ) {
                    setScheduledDate(moment(scheduled_date).toISOString())
                    setPlannedDate(moment(planned_date).toISOString())
                    setCompletionTime(moment(completion_time).toISOString())
                }
               
                const existData = {
                    description: description,
                    maintenanceEngineer: maintenance_engineer,
                    approvedBy: approved_by,
                    cost: cost,
                    status: status,
                    remarks: remarks,
                    maintenanceSchedule: {id: maintenance_schedule_id, offsetDays: offset_time_days }
                }
                let filteredUsers = [];
                    if (maintenance_engineer) {
                        const engineerNames = maintenance_engineer.split(',').map(name => name.trim());
                        engineerNames.forEach(name => {
                            if (!filteredUsers.includes(name)) {
                                filteredUsers.push(name);
                            }
                        });
                        setSelectedMaintnceEngineers(filteredUsers);
                       
                    }
                setForm(existData)
                setShowLoader(false)
            })
        }
    }, []);

    const calculateScheduledDate = (scheduleDays, startDate) => {
        const startDateObj = new Date(startDate)

        const actualMaintenanceDate = new Date(startDate);
        actualMaintenanceDate.setDate((startDateObj.getDate() + scheduleDays) - 1);

        return {
            actualMaintenanceDate: actualMaintenanceDate.toISOString(),
        }

    }

    useEffect(()=> {
        if ( (maintenanceSchedules?.length > 0 && form?.maintenanceSchedule) && (!scheduledDate || !maintenanceId) ){
            const actualScheduleDate = calculateScheduledDate(form.maintenanceSchedule?.offsetDays, maintenanceSchedules[0]?.assets?.operation_start_date)
            setScheduledDate(actualScheduleDate?.actualMaintenanceDate)
        }
    },[form.maintenanceSchedule, maintenanceSchedules])

    const handleChange = (e, fieldName) => {
        const { value } = e.target;

        if ( fieldName === 'cost' ) {
            const isValid = /^[0-9]*\.?[0-9]*$/.test(value);
            if (!isValid) {
                return;
            }
        }
        const data = (prevState) => {
            let newData = {
                ...prevState,
                [fieldName]: value
            };
            return newData;
        };

        if ( fieldName === 'status' && value !== 'Completed' ) {
            setCompletionTime(null)
        }
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        if(err['completionTime'] && fieldName === 'status') {
            err['completionTime'] = null;
        }
        setErrors(err);
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const handleFileChange = (event) => {
        if (event.target.files.length) {
            const files = Array.from(event.target.files);
            const newFiles = files.map((file, index) => {
                return {
                    id: selectedFile?.length + index,
                    fileUrls: URL.createObjectURL(file),
                    type: 'image',
                    file,
                };
            });
    
            setUploadedFile((prevUploadedFiles) => [...prevUploadedFiles, ...files]);
            setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, ...newFiles]);
    
            event.target.value = '';
        }
    
        if (disableButton) {
            setDisableButton(false);
        }
    };

    const handleConfirmUpload = () => {
        const formData = new FormData();
        if (uploadedImage) {
            formData.append('imageFile', uploadedImage);
            formData.append('type', 'image');
            formData.append('size', uploadedImage?.size);
        }
        setShowConfirmUploadDialog(false)
    }

    const handleDeleteMedia = (imageId) => {
        const deleteMedia = [...selectedFile];
        const itemToDelete = deleteMedia.findIndex(item => item.id == imageId);
        deleteMedia.splice(itemToDelete, 1)
        const deleteUploadedFile = [...uploadedFile];
        const updatedFiles = deleteUploadedFile.filter((item) => item.id !== imageId);
        setUploadedFile(updatedFiles)
        setSelectedFile(deleteMedia)
        setDeletedItem((prevItems) => [...prevItems, imageId]);
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        maintenanceLogQueryParams ? navigate(`/client/${client_id}/client-assets/${id}/${tabId}?${maintenanceLogQueryParams.toString()}`) : navigate(`/client/${client_id}/client-assets/${id}/${tabId}`)
    }

    const handleMaintncEngnrAutoCompleteChange = (event, value) => {
        const err = Object.assign({}, errors);
        if (err['maintenanceEngineer']) {
            err['maintenanceEngineer'] = null;
        }
        setErrors(err)
        setSelectedMaintnceEngineers(value);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const validateData = () => {
        const err = { ...errors };
        let hasErrors = false;

        if (!form.description || form.description.trim() === '') {
            hasErrors = true;
            err.description = 'This field is mandatory';
        }

        if (!form.maintenanceSchedule) {
            hasErrors = true;
            err.maintenanceSchedule = 'This field is mandatory';
        }

        if (selectedMaintnceEngineers?.length === 0) {
            hasErrors = true;
            err.maintenanceEngineer = 'This field is mandatory';
        }

        if (!form.approvedBy || form.approvedBy.trim() === '') {
            hasErrors = true;
            err.approvedBy = 'This field is mandatory';
        }

        if (!form.cost || form.cost.trim() === '') {
            hasErrors = true;
            err.cost = 'This field is mandatory';
        }

        if (!form.status) {
            hasErrors = true;
            err.status = 'This field is mandatory';
        }

        if (!scheduledDate) {
            hasErrors = true;
            err.scheduledDate = 'This field is mandatory';
        }else  if (!isValidDate(scheduledDate)) {
            hasErrors = true;
            err.scheduledDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!plannedDate) {
            hasErrors = true;
            err.plannedDate = 'This field is mandatory';
        }else  if (!isValidDate(plannedDate)) {
            hasErrors = true;
            err.plannedDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if ( completionTime && (completionTime > new Date())){
            hasErrors = true;
            err.completionTime = 'Future date cannot be selected'
        }

        if ( form.status === 'Completed' && !completionTime ) {
            hasErrors = true;
            err.completionTime = "This field is mandatory"
        }
        else if ( !isValidDate(completionTime)) {
            err.completionTime = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    }

    const handleSubmit = async() => {
        setDisableButton(true)
        const checkForm = validateData();

        const engineerNames = selectedMaintnceEngineers?.map(engineer => engineer).join(', ');

        if ( checkForm ) {
            const data = {
                description: form.description,
                maintenanceEngineer: engineerNames,
                approvedBy: form.approvedBy,
                cost: form.cost,
                status: form.status,
                remarks: form.remarks,
                scheduledDate,
                plannedDate,
                completionTime,
                assetId: id,
                client_id,
                maintenanceSchedule: form.maintenanceSchedule,
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data))

            if (deletedItem?.length > 0) {
                dispatch(deleteMaintenanceMedia(client_id, maintenanceId, deletedItem)).then(res => { })
            }

            if (uploadedFile?.length > 0) {
                uploadedFile.forEach((file) => {
                    formData.append('imageFiles', file);
                    formData.append('type', 'image');
                    formData.append('size', file.size);

                });
            }
            
            if (maintenanceId) {
                dispatch(updateMaintenanceLog(formData, maintenanceId)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            } else {
                dispatch(createMaintenanceLog(formData)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            }
        }
    };

    if (showLoader) {
        return <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', width: '100vw', height: '500px',  alignItems: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    }

    if (maintenanceId && itemNotFound && !showLoader ) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <Paper elevation={1} style={{ margin: '16px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container >
                    <Grid item md={12} className='w3-padding' style={{ display: 'flex' }}>
                        <IconButton
                            onClick={() => {
                                maintenanceLogQueryParams ? 
                                navigate(`/client/${client_id}/client-assets/${id}/${tabId}?${maintenanceLogQueryParams.toString()}`) :
                                navigate(`/client/${client_id}/client-assets/${id}/${tabId}`)
                            }}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <h6>{maintenanceId ? 'Edit Maintenance Log' : 'Create New Maintenance Log'}</h6>
                    </Grid>
                    <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                 <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Maintenance Schedule *</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        disabled={maintenanceId}
                                        value={form.maintenanceSchedule}
                                        name="maintenanceSchedule"
                                        label="Maintenance Schedule"
                                        onChange={(e) => handleChange(e, 'maintenanceSchedule')}
                                        renderValue={(selected) => {
                                            const selectedItem = maintenanceSchedules?.find(item => {
                                                return item.id === selected.id
                                            });
                                            return (
                                                <div>
                                                    {selectedItem ? `${selectedItem.offset_time_days} Days - ${selectedItem.action}` : ''}
                                                </div>
                                            );
                                        }}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                    >
                                        {maintenanceSchedules?.map(item => <MenuItem key={item.id} value={{ id: item?.id, offsetDays: item?.offset_time_days }}><ListItemText primary={`${item?.offset_time_days} Days`} secondary={item?.action} /></MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.maintenanceSchedule && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.maintenanceSchedule}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    multiline
                                    rows={4}
                                    required
                                    value={form.description}
                                    onChange={(e) => handleChange(e, 'description')}
                                />
                            </Grid>
                            {errors.description && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.description}</div>}
                            <Grid container spacing={2}>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Scheduled Date*"
                                            format="DD/MM/YYYY"
                                            required
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="scheduledDate"
                                            value={dayjs(scheduledDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setScheduledDate(newValue)
                                                setErrors({ ...errors, scheduledDate: null });
                                            }}
                                            disabled
                                        />
                                    </LocalizationProvider>
                                    {errors.scheduledDate && <div className='error-text'>{errors.scheduledDate}</div>}
                                </Grid>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Planned Date*"
                                            format="DD/MM/YYYY"
                                            required
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="plannedDate"
                                            value={dayjs(plannedDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setPlannedDate(newValue)
                                                setErrors({ ...errors, plannedDate: null });
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {errors.plannedDate && <div className='error-text'>{errors.plannedDate}</div>}
                                </Grid>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label={ form?.status === 'Completed' ? 'Completion Date *' : 'Completion Date'}
                                            format="DD/MM/YYYY"
                                            required={form.status === 'Completed'}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="completionTime"
                                            value={dayjs(completionTime)}
                                            onChange={(newValue) => {
                                                setCompletionTime(newValue)
                                                setErrors({ ...errors, completionTime: null });
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                            }}
                                            disabled={form.status !== 'Completed'}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                    {errors.completionTime && <div className='error-text'>{errors.completionTime}</div>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <Autocomplete
                                        fullWidth
                                        required
                                        multiple
                                        options={greenUsers || []}
                                        value={selectedMaintnceEngineers}
                                        filterSelectedOptions
                                        isOptionEqualToValue={(option, value) => option === value}
                                        onChange={handleMaintncEngnrAutoCompleteChange}
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <ListItemText primary={option} />
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            label="Maintenance Engineer *" 
                                            placeholder="Maintenance Engineer" 
                                            />
                                        )}
                                    />
                                {errors.maintenanceEngineer && <div className='error-text'>{errors.maintenanceEngineer}</div>}
                                </Grid>
                                <Grid item md={6} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <Autocomplete
                                        fullWidth
                                        required
                                        options={approvers || []}
                                        value={approvers.find(user => user.name === form.approvedBy) || null}
                                        isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                        onChange={(event, newValue) => handleChange({ target: { name: 'approvedBy', value: newValue?.name } }, 'approvedBy')}
                                        getOptionLabel={(option) => option?.name || ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Approver *"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                {errors.approvedBy && <div className='error-text'>{errors.approvedBy}</div>}
                                </Grid>
                            </Grid>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    label="Maintenance Cost(₹)"
                                    value={form.cost}
                                    onChange={(e) => handleChange(e, 'cost')}
                                />
                            </Grid>
                            {errors.cost && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.cost}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl fullWidth required>
                                    <InputLabel>Status</InputLabel>
                                        <Select
                                        label="Status"
                                        value={form.status}
                                        onChange={(e) => handleChange(e, 'status')}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Doing">Doing</MenuItem>
                                            <MenuItem value="Completed">Completed</MenuItem>
                                        </Select>
                                </FormControl>
                            </Grid>
                            {errors.status && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.status}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    multiline
                                    rows={4}
                                    value={form.remarks}
                                    onChange={(e) => handleChange(e, 'remarks')}
                                />
                            </Grid>
                            <Grid container item md={12} className='w3-margin-bottom'>
                                <label htmlFor="logo-file">
                                    <input
                                        style={{ display: 'none' }}
                                        required
                                        id="logo-file"
                                        name="logoFile"
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        onChange={handleFileChange}
                                        multiple
                                    />

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Button
                                                className="w3-margin-top"
                                                color="primary"
                                                variant="outlined"
                                                size="medium"
                                                component="span">
                                                Upload Images
                                            </Button>
                                        </div>
                                    </div>
                                </label>
                            </Grid>
                            <Grid container item md={12} className='w3-margin-bottom'>
                                {selectedFile && selectedFile?.map((mediaItem, idx) => {
                                    if (!mediaItem?.fileUrls) {
                                        return false;
                                    }
                                    const url = new URL(mediaItem.fileUrls?.length === 1 ? mediaItem?.fileUrls[0] : mediaItem?.fileUrls);
                                    const srcUrl = url.href;
                                    return (
                                        <Grid item md={4} style={{ position: 'relative'}}> 
                                            <Tooltip title="Delete" style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                                <IconButton className='tm-runcase-drawer-delete-icon' aria-label="delete" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteMedia(mediaItem.id);
                                                }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <img style={{ padding: '5px' }} width="100%" height="auto" src={srcUrl} alt={`uploadedImage-${idx}`} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                            {id && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                        // onClick={() => triggerDelete()}
                                        >
                                        Delete
                                        </Button>
                                )}
                            </Grid> */}
                        <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }} className='w3-margin-bottom'>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    maintenanceLogQueryParams ?
                                    navigate(`/client/${client_id}/client-assets/${id}/${tabId}?${maintenanceLogQueryParams.toString()}`) :
                                    navigate(`/client/${client_id}/client-assets/${id}/${tabId}`)
                                }}
                            >
                                Cancel</Button>
                                <Button
                                    // disabled={disableButton}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                    disabled={disableButton}
                                >
                                    Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {alertMessage ?
                    (
                        <AlertDialog
                            handleClose={handleCloseDialog}
                            message={alertMessage}
                            type={msgType}
                            handleDialogClose={() => setAlertMessage(null)}
                        />
                    )
                    : null
                }
                {showConfirmUploadDialog
                    ? (
                        <ConfirmDialog
                            handleCancel={() => {
                                setShowConfirmUploadDialog(false)
                            }}
                            handleOk={handleConfirmUpload}
                            message={'Are you sure to Upload Image?'}
                        />
                    ) : null}
            </Grid>
        </Paper>
    )
}