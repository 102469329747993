import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import SearchBox from '../Common/SearchBox';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getAllAssetsLocationDetails, getAllClientAssets } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { calculateDueDays, updateParams } from '../../utils';
import Filter from '../Common/Filter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaceIcon from '@mui/icons-material/Place';

export default function ClientAssetList () {

    let { client_id: client_id } = useParams()
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])

    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }
    const location = useLocation();
    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(null)
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [showMapButton, setShowMapButton] = useState(false);
    const [locationDetails, setLocationDetails] = useState([]);
    const [showMapModal, setShowMapModal] = useState(false);

    const tableColumns = ['Sl. No.', 'Asset Name', 'Category', 'Facility', 'Serial Number', 'Asset Cost', 'Associated Meters', 'Maintenance Schedule', 'Maintenance Status', '']
    let tableContent = null;
    const mapRef = useRef(null);

    const scrollToMap = () => {
        mapRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,
        }
        dispatch(getAllClientAssets(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res?.data?.clientAssetData)
                setTotalPages(res?.data?.totalPages)
                setTotalCount(res?.data?.totalItems);
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false)
            }
        });
        dispatch(getAllAssetsLocationDetails(client_id)).then((res) => {
            if (res && res.status === 200) {
                setLocationDetails(res?.data?.locationData)
            }
        })
    };

    useEffect(() => {
        if (showMapModal) {
            setTimeout(() => {
                const mapContainer = document.getElementById('modal-map');
                if (mapContainer) {
                    initMap();
                }
            }, 100);
        }
    }, [showMapModal]);
    

    const categoryColorMap = {
        'Supply': 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        'Distribution': 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
        'Treatment': 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
        'Sanitary & Plumbing': 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
        'Water Conservation & Monitoring': 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
        'Cooling & HVAC Water Systems': 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
        'Fire Safety': 'http://maps.google.com/mapfiles/ms/icons/lightblue-dot.png',
        'Stormwater Management': 'http://maps.google.com/mapfiles/ms/icons/brown-dot.png',
    };

    const getMarkerIconUrl = (category) => {
        return categoryColorMap[category] || 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    };
    

    const initMap = () => {
        const map = new window.google.maps.Map(document.getElementById('modal-map'), {
            center: { lat: 12.9715987, lng: 77.5945627 },
            zoom: 8,
        });

        locationDetails.forEach((item) => {
            if (item.geo_proof_lat && item.geo_proof_lon) {
                const markerIcon = getMarkerIconUrl(item.category);
                const marker = new window.google.maps.Marker({
                    position: { lat: parseFloat(item.geo_proof_lat), lng: parseFloat(item.geo_proof_lon) },
                    map,
                    icon: markerIcon,
                    // title: item.name,
                });
                const infoWindow = new window.google.maps.InfoWindow({
                    content: `
                        <div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.5; max-width: 310px;">
                            <strong style="font-weight: bold; font-size: large;">${item.name}</strong><br>
                            <strong>${item.category}</strong><br>
                            <span style="color: #1976d2;">Latitude:</span> ${item.geo_proof_lat} | <span style="color: #1976d2;">Longitude:</span> ${item.geo_proof_lon}
                        </div>
                    `,
                });
                // infoWindow.open(map, marker);
                marker.addListener('click', () => {
                    infoWindow.open(map, marker);
                });
            }
        });
    };

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    const getMaintenanceStatus = (dueDays, maintenanceLogs, maintenanceValidCheck) => {
        if ( maintenanceValidCheck ){
            return null
        }
        const maintenanceLogExist = maintenanceLogs.find(item => item.offset_time_days === dueDays )
        if ( maintenanceLogExist ) {
            return { status: maintenanceLogExist.status };
        } else {
            return { status: 'Pending' };
        }
    }

    const getStatusClassName = (item) => {
        if (item === 'Pending') {
            return 'w3-red';
        } else if (item === 'Completed') {
            return 'w3-green';
        } else{
            return 'w3-yellow';
        }
    };

    let assetList = []
    const itemsPerPage = 20;

    if ( showLoader ) {
        assetList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (!showLoader && responseData?.length <= 0) {
        assetList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className="w3-text-grey">No Data Found</h5>
                </TableCell>
            </TableRow>
        );
    } else {
        assetList = responseData?.map((item, index) => {

            const maintenanceDueDays = calculateDueDays(item?.operation_start_date, item?.componentSchedule, item?.assetMaintenance, item?.operation_end_date)
            const maintenanceStatus = getMaintenanceStatus(maintenanceDueDays?.scheduleItem?.offset_time_days ? maintenanceDueDays?.scheduleItem?.offset_time_days : null, item?.assetMaintenance, maintenanceDueDays?.maintenanceCompleted ? maintenanceDueDays?.maintenanceCompleted : null )
            return (
                <TableRow
                    onMouseEnter={() => {
                        setHoveredRowId(item?.unique_id);
                    }}
                    onMouseLeave={() => {
                        setHoveredRowId(null);
                    }}
                >
                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.category}</TableCell>
                    <TableCell>{item?.facility?.facility_name}</TableCell>
                    <TableCell>{item?.serial_number}</TableCell>
                    <TableCell>{item?.cost ? `₹ ${item?.cost}` : '--'}</TableCell>
                    <TableCell>
                        {item?.assetMeter?.meter ? (
                            <Chip 
                            key={item?.assetMeter?.meter?.mid}
                            size="small"
                            color="primary"
                            label={item?.assetMeter?.meter?.meterid}
                            onClick={() => { 
                                navigate(`/client/${client_id}/meters/trending-analysis/${item?.assetMeter?.meter?.mid}`)
                            }}
                            sx={{
                                '.MuiChip-label': {
                                padding: '0px',
                                },
                            }}
                            style={{
                                fontSize: '0.75rem', 
                                height: '20px',      
                                padding: '0 4px',   
                                margin: '2px', 
                            }}
                            />
                        ) : (
                            '--'
                        )}
                    </TableCell>
                    <TableCell>
                            <div style={{ color: maintenanceDueDays?.overdueDays ? 'red' : 'black', fontWeight:500 }}>{maintenanceDueDays?.overdueDays ? `Overdue By ${maintenanceDueDays?.overdueDays} Days` : (maintenanceDueDays?.dueDays !== null ? `In ${maintenanceDueDays?.dueDays} Days` : '--' )}</div>
                            <div style={{ fontSize: '10px'}}>{maintenanceDueDays?.actualMaintenanceDate ? `(${maintenanceDueDays?.actualMaintenanceDate})` : null}</div>
                    </TableCell>
                    <TableCell>{ maintenanceStatus ? (<Chip
                        style={{ width: '78px', display: 'flex', justifyContent: 'center'}}
                        size="small"
                        className={getStatusClassName(maintenanceStatus?.status)}
                        label={maintenanceStatus?.status}
                    />) : `--` }</TableCell>
                    <TableCell>
                        <Box component="div" display="flex" justifyContent={"right"} >
                            <Tooltip title="Details">
                                <IconButton aria-label="details"
                                    style={{ opacity: hoveredRowId === item?.unique_id ? 1 : 0, paddingLeft: '0px', marginRight: '10px' }}
                                    onClick={(e) => {
                                        navigate(`/client/${client_id}/client-assets/${item?.unique_id}/details`)
                                        localStorage.setItem('clientAssetQueryParams', queryParams);
                                    }}
                                >
                                    <VisibilityIcon className="cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.unique_id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => { 
                                        navigate(`/client/${client_id}/client-assets/${item.unique_id}/maintenance`)
                                        localStorage.setItem('clientAssetQueryParams', queryParams)
                                    }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </TableCell>
                </TableRow>
            )
        });
    }

    tableContent = assetList

    const handleOpenMapModal = () => {
        setShowMapModal(true);
    };

    const handleCloseMapModal = () => {
        setShowMapModal(false);
    };

    return (
        <div className='w3-padding-large'>
            <Grid container>
                <Grid item md={12}>
                    <Grid item container spacing={8} paddingY={2}>
                        <Grid item md={6}>
                            <Button
                                variant="contained"
                                onClick={() => { 
                                    if (localStorage.getItem('clientAssetQueryParams')) {
                                        localStorage.removeItem('clientAssetQueryParams');
                                    }
                                    navigate(`/client/${client_id}/client-assets/create`)   
                                }}
                            >Add New Asset</Button>
                            <Button
                                variant="outlined"
                                style={{ marginLeft: '10px' }}
                                onClick={handleOpenMapModal}
                                startIcon={<PlaceIcon />}
                            >
                                View Assets on Map
                            </Button>
                        </Grid>
                        <Grid item md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={11} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Asset Name / Serial Number'
                                        search={handleSearch}
                                    />
                                </Grid>
                                <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton 
                                        onClick={() => setShowFilterDialog(true)} 
                                        title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Filter
                        filterArea="Client Assets"
                        showFilterDialog={showFilterDialog}
                        setShowFilterDialog={setShowFilterDialog}
                        updateQueryParams={updateQueryParams}
                        queryParams={queryParams}
                        setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                    />
                    <Grid paddingX={0} paddingY={1}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" style={{ tableLayout: 'auto' }}>
                                <TableHead>
                                    <TableRow>
                                    {tableColumns.map(tCol => (
                                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>{tCol}</TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableContent}
                                </TableBody>    
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {( responseData && responseData.length > 0 ) && ( 
                    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
                            <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                            </span>
                        </Grid>
                        <Pagination
                            page={parseInt(existingParams?.page) || 1}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Grid>
                )}
            </Grid>
            {showMapModal && (
                <Dialog
                    open={showMapModal}
                    onClose={handleCloseMapModal}
                    maxWidth="lg"
                    fullWidth
                >
                <DialogTitle>Client Assets</DialogTitle>
                <DialogContent>
                    <div style={{ width: '100%', height: '400px'}}>
                        <div id="modal-map" style={{ width: '100%', height: '100%' }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMapModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            )}
        </div>
    )
}