import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { showCircularProgress } from '../../utils/Common';

export default function AssetConsolidatedReport({ fromDate, toDate, consolidatedMetersData, projectName }) {

    const [reportData, setReportData] = useState([]);
    const defaultFromDate = moment().subtract(14, 'days').toDate();
    const defaultToDate = moment().subtract(1, 'days').toDate();

    useEffect(() => {
        const updatedData = consolidatedMetersData.map(meter => {
            const runningTime = meter.runningHrs || 0;
            const rating = meter.rating || 0;
            const unitPrice = meter.unitPrice || 0;
            const totalConsumption = rating * runningTime;
            const totalCost = totalConsumption * unitPrice;

            return {
                assetName: meter.assetName,
                meterName: meter.meterName,
                runningHrs: runningTime,
                rating: rating,
                unitPrice: unitPrice,
                consumption: totalConsumption,
                cost: totalCost,
            };
        });

        setReportData(updatedData);
    }, [consolidatedMetersData]);

    const totalRunningHrs = reportData.reduce((sum, meter) => sum + meter.runningHrs, 0);
    const totalConsumption = reportData.reduce((sum, meter) => sum + meter.consumption, 0);
    const totalCost = reportData.reduce((sum, meter) => sum + meter.cost, 0);

    if (reportData?.length === 0) {
        return showCircularProgress;
    }

    return (
        <div className="reports-tables-container" style={{ marginTop: '50px' }}>
            <table className="table-header day-wise-report-table table-bordered monthly-report-table">
                <tbody>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>
                            {projectName}
                        </th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>
                            From: {moment(fromDate || defaultFromDate).format('YYYY-MM-DD')} 00:00 AM - To: {moment(toDate || defaultToDate).format('YYYY-MM-DD')} 11:59 PM
                        </th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                        >
                            Operational - Consolidated Report - Powered by{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.greenvironmentindia.com/">
                                Greenvironment India
                            </a>
                        </th>
                    </tr>
                </tbody>
            </table>
            <table className="day-wise-report-table row-border order-column" style={{ width: '100%' }}>
                <thead style={{ overflowX: 'auto', width: '100%' }}>
                    <tr className="table-header-light-bg">
                        <th className="tg-e9yv">Asset Name</th>
                        <th className="tg-e9yv">Meter Name</th>
                        <th className="tg-e9yv">Rating (kW)</th>
                        <th className="tg-e9yv">Running Hours</th>
                        <th className="tg-e9yv">Consumption (kWh)</th>
                        <th className="tg-e9yv">Unit Price</th>
                        <th className="tg-e9yv">Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map((meter, index) => (
                        <tr key={index}>
                            <td style={{ fontWeight: 'bold' }}>{meter.assetName}</td>
                            <td>{meter.meterName}</td>
                            <td>{meter.rating}</td>
                            <td>{meter.runningHrs.toFixed(2)}</td>
                            <td>{meter.consumption.toFixed(2)}</td>
                            <td>₹ {meter.unitPrice}</td>
                            <td>₹ {meter.cost.toFixed(2)}</td>
                        </tr>
                    ))}
                    {/* Total row */}
                    <tr className="table-header-light-bg" style={{ fontWeight: 'bold' }}>
                        <td colSpan="3" style={{ textAlign: 'right' }}>Total</td>
                        <td>{totalRunningHrs.toFixed(2)}</td>
                        <td>{totalConsumption.toFixed(2)}</td>
                        <td>-</td>
                        <td>₹ {totalCost.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
